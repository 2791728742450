import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import ContactForm from "$components/ContactForm"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

import backgroundOffice from "./assets/background-office.jpg"

const Background = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${backgroundOffice});
  background-size: cover;
  margin: 0;
  overflow: hidden;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: auto;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
  }
`

const ButtonLink = styled.button`
  background-color: ${props => props.theme.goldenDream};
  border-radius: 3px;
  border-bottom: outset;
  box-sizing: border-box;
  color: ${props => props.theme.tundora};
  display: block;
  font-size: 1.2rem;
  margin: 0 16px;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  :first-child {
    margin-left: 0;
  }

  :hover {
    background-color: ${props => props.theme.darken(props.theme.goldenDream)};
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 16px 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`
const CampaignContact = styled.div`
  margin: 0 0 0 20px;
  flex: 1;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 0;
  }
`

const CampaignContainer = styled(Container)`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(
      to right,
      ${props => props.theme.rouge} -25%,
      ${props => props.theme.goldenDream} 125%
    );
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  padding: 40px;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      linear-gradient(
        to bottom,
        ${props => props.theme.rouge} -25%,
        ${props => props.theme.goldenDream} 125%
      );
    box-sizing: border-box;
    display: block;
    padding: 0 16px;
    width: 100%;
  }
`

const CampaignText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 20px 0 0;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 0 0 20px;
  }
`

const title = "Accounting and Legal IT Specialists | Lotus Network Solutions"

export default () => {
  return (
    <>
      <SEO noindex title={title} />
      <Header />
      <main>
        <Background>
          <h1>Let LOTUS handle IT.</h1>
          <CampaignContainer>
            <CampaignText>
              <h2>How can LOTUS help your practice?</h2>
              <p>
                Behind every successful practice is robust IT infrastructure. Is
                your business secure, up-to-date and regularly backed up? Or are
                unresolved maintenance issues costing you valuable time and
                money?
              </p>
              <p>
                Lotus Network Solutions specialises in managed IT services for
                accounting and legal practices. Ask us about Work from Home
                solutions, backup systems, networking and more - Lotus is here
                to help.
              </p>
              <p>
                Click the links below for more information or contact us
                directly to get a quote for your business.
              </p>
              <ButtonContainer>
                <ButtonLink as={Link} to="/services/">
                  See all services &rarr;
                </ButtonLink>
                <ButtonLink as="a" href="tel:1300767428">
                  <ion-icon name="call-outline" size="1rem" /> 1300 767 428
                </ButtonLink>
              </ButtonContainer>
            </CampaignText>
            <CampaignContact>
              <h2>Get your free quote</h2>
              <ContactForm />
            </CampaignContact>
          </CampaignContainer>
        </Background>
      </main>
      <Footer />
    </>
  )
}
